var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, Typography, Divider, } from '@mui/material';
import { useLocales } from '../../locales';
// ----------------------------------------------------------------------
var visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};
export default function TableHeadCustom(_a) {
    var order = _a.order, orderBy = _a.orderBy, _b = _a.rowCount, rowCount = _b === void 0 ? 0 : _b, headLabel = _a.headLabel, _c = _a.numSelected, numSelected = _c === void 0 ? 0 : _c, onSort = _a.onSort, onSelectAllRows = _a.onSelectAllRows, sx = _a.sx, _d = _a.firstCase, firstCase = _d === void 0 ? false : _d, labelFirstCase = _a.labelFirstCase;
    var currentLang = useLocales().currentLang;
    return (_jsx(TableHead, __assign({ sx: sx }, { children: _jsxs(TableRow, { children: [onSelectAllRows && (_jsx(TableCell, __assign({ padding: "checkbox" }, { children: _jsx(Checkbox, { indeterminate: numSelected > 0 && numSelected < rowCount, checked: rowCount > 0 && numSelected === rowCount, onChange: function (event) {
                            return onSelectAllRows(event.target.checked);
                        } }) }), 0)), firstCase && (_jsxs(TableCell, __assign({ style: {
                        width: '50px!important',
                        overflow: 'hidden',
                        borderTopLeftRadius: '10px',
                    } }, { children: [_jsx(Typography, __assign({ noWrap: true, variant: "subtitle2", align: "right", p: 1 }, { children: labelFirstCase && labelFirstCase[0].label })), _jsx(Divider, { style: (currentLang === null || currentLang === void 0 ? void 0 : currentLang.value) === 'fr' || (currentLang === null || currentLang === void 0 ? void 0 : currentLang.value) === 'en'
                                ? { rotate: '50deg' }
                                : { rotate: '-50deg' } }), _jsx(Typography, __assign({ variant: "subtitle2", noWrap: true, p: 1 }, { children: labelFirstCase && labelFirstCase[1].label }))] }))), headLabel.map(function (headCell, index) { return (_jsx(TableCell, __assign({ align: headCell.align || 'left', sortDirection: orderBy === headCell.id ? order : false, sx: { width: headCell.width, minWidth: headCell.minWidth } }, { children: onSort ? (_jsxs(TableSortLabel, __assign({ hideSortIcon: true, active: orderBy === headCell.id || undefined, direction: orderBy === headCell.id ? order : 'asc', onClick: function () { return (headCell.id ? onSort(headCell.id) : undefined); }, sx: { textTransform: 'capitalize' } }, { children: [headCell.label, orderBy === headCell.id ? (_jsx(Box, __assign({ sx: __assign({}, visuallyHidden) }, { children: order === 'desc' ? 'sorted descending' : 'sorted ascending' }))) : null] }))) : (_jsx(Typography, __assign({ noWrap: true }, { children: headCell.label }))) }), index + 1)); })] }) })));
}
